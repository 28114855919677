import React from 'react'

import { Footer } from '../common/footer'
import { Navigation } from '../common/navigation'
import { GlobalHelmet } from '../common/seo/globalHelmet'
import { CMSWrapper } from '../components/cmsWrapper'
import { StaticReferenceModules } from '../components/references/staticReferenceModules'
import { StationsShortlist } from '../components/stations/stationsShortlist'
import { graphql } from 'gatsby'
import { AREA_MTLA_PATH, AREA_MTLA } from '../config/constants'
import { Button } from '../common/button'

export default function Index({ pageContext, data }) {
  const areaPageReferences = data.allReference.nodes
  return (
    <CMSWrapper>
      <GlobalHelmet title="UKE jukebox - MTL/MTLA" />
      <main>
        <Navigation />
        <header className="bg-img-yellow divider-left header--static">
          <div className="row align-items-center">
            <div className="col-md-5 offset-md-1 col-10 offset-1 text-center">
              <img
                src="/img/handlettering/handlettering-bei-uns-stimmt-die-chemie.svg"
                alt="Handlettering: Bei uns stimmt die Chemie"
                title="Bei uns stimmt die Chemie"
                width="370"
              />
            </div>

            <div className="col-md-5 offset-md-0 col-10 offset-1 text-center">
              <img
                src="/img/mtla/illustration-mtla.png"
                alt="Illustration: MTLA"
              />
            </div>

            <div className="col-md-10 col-10 offset-1 text-center mt-4">
              <h1>
                Medizinsche:r Technolog:in für Laboratoriumsanalytik (MTL /
                MTLA)
              </h1>
              <h2>
                Was du nicht alles siehst! Du kennst den Menschen zwar nicht,
                dessen Proben du analysierst, hast ihn bei deiner Arbeit aber
                stets im Kopf und spielst eine tragende Rolle auf dem Weg zu
                seiner Gesundheit!
              </h2>
            </div>
          </div>
        </header>

        <section id="matching-stats">
          <div id="stats" className="row align-items-center text-center">
            <div className="col-md-4 col-12">
              <h3>Unser Bereich in Zahlen:</h3>
            </div>
            <div className="col-md-4 col-6">
              <figure>
                <img
                  src="/img/icons/icon-team.png"
                  height="80"
                  width="80"
                  alt="Team"
                  loading="lazy"
                />
                <figcaption>
                  <h4>
                    MTs im UKE <br />
                    <span>&gt;300</span>
                  </h4>
                </figcaption>
              </figure>
            </div>
            <div className="col-md-4 col-6">
              <figure>
                <img
                  src="/img/icons/icon-labor.png"
                  height="80"
                  width="80"
                  alt="Labore"
                  loading="lazy"
                />
                <figcaption>
                  <h4>
                    Labore <br />
                    <span>&gt;100</span>
                  </h4>
                </figcaption>
              </figure>
            </div>
          </div>
        </section>

        <section className="bg-blue divider-right">
          <div className="row align-items-center">
            <div className="col-md-10 offset-md-1 col-12">
              <h2>Du siehst, was andere nicht sehen!</h2>
              <p>
                Mit deinen Laborergebnissen lieferst du den Schlüssel für die
                gesicherte ärztliche Diagnose. UKE ohne dich? Geht gar nicht!
              </p>
              <p>
                Als Labor-Profi untersuchst du selbständig Körperflüssigkeiten
                und Gewebe: Blut, Urin, Stuhlproben, Liquor, Fruchtwasser,
                Haarproben, Gewebeproben aller Art, usw. Du schneidest, färbst
                und mikroskopierst Gewebeproben; du untersuchst Blut auf Viren,
                Bakterien, auf Pilze und Parasiten. Und immer mit dem Anspruch,
                deine Ergebnisse schnell und präzise zu liefern. Davon hängt in
                manchen Fällen vielleicht ein Schicksal ab.
              </p>
              <p>
                Im Zentrum für Diagnostik kannst du im Institut für Med.
                Mikrobiologie, Virologie und Hygiene, im Institut für Klinische
                Chemie und Laboratoriumsmedizin, im Institut für
                Transfusionsmedizin, im Institut für Immunologie, im Institut
                für Pathologie, im Institut für Neuropathologie oder im Institut
                für Rechtsmedizin in 30 verschiedenen Laboren auf Spurensuche
                gehen: von A wie Adenovirus bis Z wie Zytologie. Dort sind
                jeweils unterschiedliche Fachkenntnisse erforderlich, aber eines
                ist gleich: <strong>Hightech ist bei uns Standard</strong>.
              </p>
              <div className="grid-container grid-container--static align-items-center">
                <div className="image-container image-container--frame image-container--static">
                  <img
                    src="/img/mtla/BSS_MTLA_Bild_1.jpg"
                    alt="BSS MTLA Bild 1"
                    title="BSS MTLA Bild 1"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-blue divider-left">
          <div className="row align-items-center">
            <div className="col-md-10 offset-md-1 col-12">
              <h2>
                Und du hast die Wahl – stöbere dich durch unsere 10 Labore!
              </h2>
              <div className="grid-container grid-container--static align-items-center">
                <div className="image-container image-container--frame image-container--static my-4">
                  <img
                    src="/img/mtla/BSS_MTLA_Bild_2.jpg"
                    alt="BSS MTLA Bild 2"
                    title="BSS MTLA Bild 2"
                    loading="lazy"
                  />
                </div>
              </div>
              <p>
                Im Labor rotierst du zwischen verschiedenen Arbeitsplätzen. Das
                macht die Arbeit spannend und interessant. Da Notfälle keinen
                Zeitplan kennen, findet Diagnostik rund um die Uhr statt. Ohne
                Nacht- und Wochenenddienste geht es daher nicht immer.
              </p>
              <p>
                <strong>
                  Unsicher, welcher Bereich der Richtige für dich ist?
                </strong>{' '}
                Kein Problem, das kannst du unkompliziert über eine Hospitation
                herausfinden. Melde dich direkt in deinem Wunschbereich oder
                kontaktiere Rita Herbrand über den Kontakt am Ende dieser Seite.
              </p>
              <p>
                <strong>
                  Sicher ist: Wir suchen Verstärkung – und wollen dich!
                </strong>
              </p>
              <p>
                Ob Berufsanfänger:in, Wiedereinsteiger:in oder erfahrene/r
                MTL/MTLA: Wir haben den{' '}
                <a href="https://www.uke.jobs/index.php?ac=search_result&search_criterion_keyword%5B%5D=Medizinischer+Technologe+f%C3%BCr+Laboratoriumsanalytik+%28MTL%29%2F+MTLA+%28w%7Cm%7Cd%29+in+Voll-und+Teilzeit&search_criterion_activity_level%5B%5D=5">
                  passenden Job
                </a>{' '}
                für dich im UKE. Bei uns ist Vieles möglich.
              </p>
              <div className="text-center">
                <Button
                  color="yellow"
                  href={`${AREA_MTLA_PATH}/stationen`}
                  className="my-2"
                >
                  Stöbern
                </Button>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-blue divider-right">
          <div className="row align-items-center">
            <div className="col-md-10 offset-md-1 col-12">
              <h2>Werde Teil unseres MTL/MTLA-Teams!</h2>
              <p>
                <strong>Das hast du davon:</strong>
              </p>
              <ul>
                <li>
                  Attraktiver Arbeitgeber: Bei uns findest du einen sicheren
                  Arbeitsplatz, kannst dich entwickeln und über dich
                  hinauswachsen
                </li>
                <li>
                  Exzellenz: Das UKE zählt zu den modernsten Kliniken Europas,
                  in der sich Spitzenmedizin, Forschung und Lehre vernetzen; bei
                  neuen Untersuchungs- und Therapieverfahren bist du stets am
                  Puls der Zeit
                </li>
                <li>
                  Spannender Arbeitsalltag, indem du Wertschätzung und
                  Unterstützung erfährst und dich voll einbringen kannst
                </li>
                <li>
                  Faire Bezahlung analog zum Tarifvertrag des Öffentlichen
                  Dienstes; weitere Vergütungsbestandteile; 30 Tage Urlaub im
                  Jahr
                </li>
                <li>Betriebliche Altersversorgung</li>
                <li>
                  Familienfreundlichkeit: Das UKE ist preisgekrönt für die
                  Vereinbarkeit von Familie, Beruf und Freizeit (kostenlose
                  Ferienbetreuung, UKE-eigene KiTa mit schicht-kompatiblen
                  Betreuungszeiten)
                </li>
                <li>
                  Zahlreiche Gesundheits- und Präventionsangebote wie
                  Akupunktur, Outdoor-Training, Segeln, Yoga, etc.
                </li>
                <li>
                  Zuschuss zum HVV-ProfiTicket, Dr. Bike Fahrradservice und
                  Fahrradleasing-Angebote
                </li>
                <li>Unterstützung bei der Wohnungssuche</li>
                <li>
                  Umzugspauschale: bis zu 2.000 Euro für die anfallenden Kosten,
                  wenn ein Umzug (von außerhalb des HVV-Gesamtbereichs) nach
                  Hamburg erforderlich ist. Sprich dieses Thema am besten gleich
                  beim Bewerbungsgespräch an
                </li>
                <li>Individuelle & intensive Einarbeitung</li>
              </ul>
              <div className="grid-container grid-container--static align-items-center">
                <div className="image-container image-container--frame image-container--static">
                  <img
                    src="/img/mtla/BSS_MTLA_Bild_3.jpg"
                    alt="BSS MTLA Bild 3"
                    title="BSS MTLA Bild 3"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-blue divider-left">
          <div className="row align-items-center">
            <div className="col-md-10 offset-md-1 col-12">
              <h2>Mach dir keinen Kopf!</h2>
              <p>
                Du denkst, das UKE sei eine Nummer zu groß für dich? Keine
                Sorge, denn bist du nie allein. Dein Team, deine Kolleg:innen
                stehen dir bei Fragen immer zur Seite.
              </p>
              <p>
                <strong>
                  Ob Patient:in oder Mitarbeiter:in – bei uns steht der Mensch
                  im Mittelpunkt.
                </strong>
              </p>
              <p>
                Wir passen aufeinander auf und sorgen dafür, dass du einen guten
                Start hast.
              </p>
              <div className="grid-container grid-container--static align-items-center">
                <div className="image-container image-container--frame image-container--static">
                  <img
                    src="/img/mtla/BSS_MTLA_Bild_4.jpg"
                    alt="BSS MTLA Bild 4"
                    title="BSS MTLA Bild 4"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-blue divider-left">
          <div className="row align-items-center">
            <div className="col-md-10 offset-md-1 col-12">
              <h2>Deine Einarbeitung: maßgeschneidert</h2>
              <h3>UKE-Einführungstage</h3>
              <p>
                Gemeinsam mit anderen neuen Mitarbeiter:innen lernst du als
                erstes berufsübergreifend 2 bis 5 Tage (je nach Einsatzbereich)
                das UKE kennen. Dabei erfährst du alles Wichtige und
                Wissenswerte rund um deinen neuen Arbeitgeber.
              </p>
              <h3>Einarbeitung vor Ort</h3>
              <p>
                Anschließend erhältst du eine bereichsspezifische Einarbeitung
                in deinem Arbeitsbereich. In der Anfangsphase stehen dir
                ausgewählte Kolleg:innen als Partner:innen zur Seite, denen du
                über die Schulter schaust.{' '}
              </p>
              <h3>Gut ankommen</h3>
              <p>
                Neu in der Hansestadt? Deine Kolleg:innen haben garantiert heiße
                Tipps parat, was-wann-wo in Hamburg so läuft. Auch das gehört
                bei uns zur Einarbeitung.
              </p>
              <h3>Urlaubsreif?</h3>
              <p>
                Jeder Anfang ist aufregend und du wirst im UKE von vielen neuen
                Eindrücken geflutet. Das muss man erstmal verarbeiten. Damit
                dabei die Erholung nicht zu kurz kommt, kannst du bei uns schon
                während der sechsmonatigen Probezeit anteilig Urlaub nehmen.
              </p>
              <h3>Du willst mehr?</h3>
              <p>
                In unseren Bereichen finden regelmäßige, interne Fortbildungen
                statt; die Teilnahme an externen Angeboten ist ebenfalls
                möglich. Unsere{' '}
                <a href="https://www.uke.de/organisationsstruktur/zentrale-bereiche/uke-akademie-fuer-bildung-karriere/index.html">
                  Akademie für Bildung & Karriere (ABK)
                </a>{' '}
                bietet dir zudem vielfältige Möglichkeiten der Fort- und
                Weiterbildung.
              </p>
              <div className="grid-container grid-container--static align-items-center">
                <div className="image-container image-container--frame image-container--static">
                  <img
                    src="/img/mtla/BSS_MTLA_Bild_5.jpg"
                    alt="BSS MTLA Bild 5"
                    title="BSS MTLA Bild 5"
                    loading="lazy"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="bg-blue divider-right">
          <div className="row align-items-center">
            <div className="col-md-10 offset-md-1 col-12">
              <h2>Mach dich warm für den Start!</h2>
              <p>
                Stöbere hier durch die Labore des Zentrums für Diagnostik, in
                denen du als MTL/MTLA gefragt bist und kontaktiere den Bereich,
                der zu dir passt.
              </p>
            </div>
          </div>
        </section>

        <StationsShortlist area={AREA_MTLA} stations={pageContext.stations} />

        <section id="stuermen" className="bg-img-green divider-right">
          <div className="row align-items-center text-center">
            <div className="col-md-3 offset-md-1 col-12">
              <img
                src="/img/illustration-pfleger-stuermen.png"
                alt="Illustration: Pfleger einzeln"
                height="311"
                width="145"
              />
            </div>
            <div className="col-md-4 offset-md-0 col-10 offset-1">
              <img
                src="/img/handlettering/handlettering-ich-bin-mtl-lasst-mich-durch.png"
                alt="Handlettering: Ich bin MTLA lasst mich durch!"
                width="400"
              />
            </div>
            <div className="col-md-3 col-12">
              <h4>
                Du weißt, was du willst:
                <br />
                Bewirb dich direkt!
              </h4>
              <Button
                color="green"
                href="mailto:bewerbung@uke.de?subject=MTLA-Initiativbewerbung über die jukebox"
              >
                Stürmen
              </Button>
            </div>
          </div>
        </section>

        <section>
          <figure>
            <div className="row align-items-center">
              <div className="col-sm-5 offset-sm-1 col-12 order-sm-1 order-1 text-center">
                <img src="img/mtla/rita-herbrand.jpg" />
              </div>
              <div className="col-sm-5 col-12 order-sm-2 order-3">
                <ul className="contact-channels">
                  <li className="phone">
                    <a href="tel:+4940741055460">+49 (0) 40 74 10 - 554 60</a>
                  </li>
                  {/*<li className="mobile"><a href="tel:+4915222">0152 22 82 </a></li>*/}
                  <li className="email">
                    <a href="mailto:herbrand@uke.de">herbrand@uke.de</a>
                  </li>
                </ul>
              </div>
              <div className="col-sm-5 offset-sm-1 col-12 order-sm-3 order-2">
                <figcaption>
                  Rita Herbrand
                  <br />
                  Medizinisch-technische Zentrumsleitung
                </figcaption>
              </div>
            </div>
          </figure>
        </section>

        <StaticReferenceModules referencesData={areaPageReferences} />

        <Footer />
      </main>
    </CMSWrapper>
  )
}

export const query = graphql`
  query MtlaReferencesQuery($referenceType: [String]) {
    allReference(
      filter: { types: { in: $referenceType }, isDraft: { eq: false } }
    ) {
      nodes {
        _id
        content {
          sections {
            _template
            bgColor
            grids {
              _template
              columns {
                _template
                altText
                description
                descriptionAlignment
                showFrame
                filename
                listStyle
                text
                textAlign
                url
                images {
                  _template
                  altText
                  description
                  filename
                  showFrame
                }
              }
              itemAlignment
              marginType
            }
          }
        }
        mediaDir
        priority
        slug
        title
      }
    }
  }
`
