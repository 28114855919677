import React from 'react'
import { useCMS } from 'tinacms'

export function Carousel({ data }) {
  const sliderId = 'superslide' + (data._id ? data._id : '')
  const sliderHref = '#' + sliderId
  const cms = useCMS()
  return (
    <div
      id={sliderId}
      className={`carousel slide ${cms.enabled ? '' : 'carousel--static'}`}
    >
      <div>
        <div className="carousel-inner">
          {data.images ? (
            data.images.map((image, galleryIndex) => (
              <CarouselImage
                key={image.filename + '_index' + galleryIndex}
                image={image}
                index={galleryIndex}
              />
            ))
          ) : (
            <div>Fügen Sie Bilder über die Sidebar hinzu</div>
          )}
        </div>
        <div className="carousel-nav">
          <button
            className="carousel-control-prev"
            data-bs-target={sliderHref}
            role="button"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            data-bs-target={sliderHref}
            role="button"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  )
}

function CarouselImage({ image, index }) {
  const cms = useCMS()
  return (
    <div className={index === 0 ? 'carousel-item active' : 'carousel-item'}>
      <figure className="m-0">
        <div
          className={`image-container ${
            image.showFrame ? 'image-container--frame' : ''
          }`}
        >
          <img
            src={
              cms.enabled
                ? `/_cms/media/file/${encodeURIComponent(image.filename)}`
                : image.filename
            }
            alt={image.altText ? image.altText : 'Slide ' + (index + 1)}
          />
        </div>
        {image.altText && (
          <figcaption className="text-center">{image.altText}</figcaption>
        )}
      </figure>
    </div>
  )
}
