import React from 'react'
import { graphql, Link, useStaticQuery } from 'gatsby'
import { useFavoritesContext } from './contexts/favoritesContext'
import {
  AREA_CARE_PATH,
  AREA_MFA_PATH,
  AREA_MTLA_PATH,
  AREA_MTRA_PATH,
} from '../config/constants'
import { useAreaContext } from './contexts/areaContext'
import { Button } from './button'

export const Navigation = ({ stationId }) => {
  const [open, setOpen] = React.useState(false)
  const [scroll, setScroll] = React.useState(false)
  const { favorites } = useFavoritesContext()
  const basePath = useAreaContext()

  const data = useStaticQuery(graphql`
    {
      allBlog {
        totalCount
      }
    }
  `)

  const isRoot = basePath === '/'

  // show blog menu item only on "Pflege" pages and only
  // if there is at least one blog entry
  const showBlogMenuItem =
    data.allBlog.totalCount > 0 && basePath === AREA_CARE_PATH

  const isActiveFavorite = favorites.includes(stationId)
  const nextQuestion =
    typeof window !== 'undefined' &&
    (window.localStorage.getItem('current-question') || '')

  const handleScroll = () => {
    setScroll(window.scrollY > 16)
  }

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  const rootNav = (
    <>
      <li>
        <Button
          color="red"
          href={AREA_CARE_PATH}
          onClick={() => setOpen(false)}
        >
          Pflege
        </Button>
      </li>
      <li>
        <Button
          color="yellow"
          href={AREA_MFA_PATH}
          onClick={() => setOpen(false)}
        >
          MFA
        </Button>
      </li>
      <li>
        <Button
          color="green"
          href={AREA_MTLA_PATH}
          onClick={() => setOpen(false)}
        >
          MTLA
        </Button>
      </li>
      <li>
        <Button
          color="grey"
          href={AREA_MTRA_PATH}
          onClick={() => setOpen(false)}
        >
          MTRA
        </Button>
      </li>
    </>
  )

  return (
    <nav
      className={`row align-items-center ${scroll ? 'sticky' : null}`}
      style={{
        top:
          process.env.NODE_ENV === 'development'
            ? 'var(--tina-toolbar-height)'
            : 0,
      }}
    >
      <div className="col-6">
        <Link to={basePath} aria-label="Home">
          <img
            id="home"
            className="m-0"
            src="/img/uke-logo.svg"
            alt="UKE Logo"
            height="73"
            width="69"
          />
        </Link>
      </div>
      <div className="col-6 text-end">
        <button
          aria-label="Menü"
          id="menu-button"
          className={`d-inline-block ${open ? 'close' : null}`}
          onClick={() => setOpen(!open)}
        />
        <Link
          to="/favoriten"
          className={`d-inline-block${isActiveFavorite ? ' filled' : ''}`}
          aria-label="Favorite"
          id="fav-button"
        >
          {favorites.length}
        </Link>
      </div>
      <div id="menu" className={open ? 'open' : ''}>
        <ul className="text-center">
          <li>
            <Button
              color="dark-blue"
              href={`${basePath}`}
              onClick={() => setOpen(false)}
            >
              Home
            </Button>
          </li>
          {basePath === AREA_CARE_PATH && (
            <li>
              <Button
                color="red"
                href={`${AREA_CARE_PATH}/spielen`}
                onClick={() => setOpen(false)}
              >
                Spielen
              </Button>
            </li>
          )}
          {!isRoot && (
            <>
              <li>
                <Button
                  color="yellow"
                  href={`${basePath}/stationen`}
                  onClick={() => setOpen(false)}
                >
                  Stöbern
                </Button>
              </li>
              <li>
                <Button
                  color="green"
                  href={`${basePath}/#stuermen`}
                  onClick={() => setOpen(false)}
                >
                  Stürmen
                </Button>
              </li>
              <li>
                <Button
                  color="dark-blue"
                  href="/favoriten"
                  onClick={() => setOpen(false)}
                >
                  Favoriten
                </Button>
              </li>
              <li>
                {nextQuestion === '/spielen/gratulation' ? (
                  <Button
                    color="red"
                    href="/testergebnis"
                    onClick={() => setOpen(false)}
                  >
                    Meine Top 10
                  </Button>
                ) : basePath === AREA_CARE_PATH ? (
                  <Button
                    disabled
                    color="red"
                    href="/testergebnis"
                    onClick={() => setOpen(false)}
                  >
                    Meine Top 10
                  </Button>
                ) : null}
              </li>
              {showBlogMenuItem && (
                <li>
                  <Button
                    color="yellow"
                    href="/blogs"
                    onClick={() => setOpen(false)}
                  >
                    Blog
                  </Button>
                </li>
              )}
            </>
          )}
          {isRoot && rootNav}
        </ul>
      </div>
    </nav>
  )
}
