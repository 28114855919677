import React from 'react'
import { useCMS } from 'tinacms'
import { VideoPlayer } from './videoPlayer'
import { Carousel } from './carousel'

export function StaticSections({ sections, backgroundClassName }) {
  return sections.map((section, index) => (
    <section key={index} className={`static-section ${section.bgColor}`}>
      <StaticGrid grids={section.grids} />
    </section>
  ))
}

function StaticGrid({ grids }) {
  return grids.map((grid, index) => (
    <div key={index}>
      <div className={`row pt-${grid.marginType}`}>
        <div className="col-md-10 offset-md-1 col-12">
          <div
            className={`grid-container grid-container--static align-items-${grid.itemAlignment}`}
          >
            <StaticColumn columns={grid.columns} />
          </div>
        </div>
      </div>
    </div>
  ))
}

function StaticColumn({ columns }) {
  return columns.map((column, index) => (
    <StaticComponent key={index} content={column} />
  ))
}

function StaticComponent({ content }) {
  const template = content._template
  let component
  if (template === 'Text') {
    component = <StaticText text={content} />
  } else if (template === 'Image') {
    component = <StaticImage image={content} />
  } else if (template === 'Video') {
    component = <StaticVideo video={content} />
  } else if (template === 'Gallery') {
    component = <StaticGallery gallery={content} />
  } else if (template === 'Button') {
    component = <StaticButton button={content} />
  } else {
    component = <div />
  }

  return <>{component}</>
}

function StaticButton({ button }) {
  return (
    <div
      className={`d-flex justify-content-${button.align} button-container--static`}
    >
      <a
        href={button.href}
        target={button.target ? '_blank' : '_self'}
        className={`button text-uppercase bg-${button.buttonBgColor}`}
      >
        {button.label}
      </a>
    </div>
  )
}

function StaticText({ text }) {
  return (
    <div
      className={`text-container ${
        text.listStyle && text.listStyle === 'anchor'
          ? 'list-style--anchor'
          : ''
      } text-container--static text-${
        text.textAlign && text.textAlign.length > 0 ? text.textAlign : 'left'
      }`}
    >
      <div dangerouslySetInnerHTML={{ __html: text.text }} />
    </div>
  )
}

export function StaticImage({ image }) {
  const cms = useCMS()
  return (
    <figure className="m-0">
      <div
        className={`image-container image-container--static ${
          image.showFrame ? 'image-container--frame' : ''
        }`}
      >
        <img
          alt={image.altText}
          src={
            cms.enabled
              ? `/_cms/media/file/${encodeURIComponent(image.filename)}`
              : image.filename
          }
          loading="lazy"
        />
      </div>
      <figcaption className="text-center">
        {image && image.altText ? image.altText : ''}
      </figcaption>
    </figure>
  )
}

export function StaticVideo({ video }) {
  return (
    <figure className="video-container video-container--static">
      <VideoPlayer videoSrcURL={video.url} videoTitle={video.description} />
      {video.description
        ? `<figcaption className="text-center">${video.description}</figcaption>`
        : null}
    </figure>
  )
}

function StaticGallery({ gallery }) {
  return <Carousel data={gallery} />
}
