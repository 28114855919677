import React from 'react'

const playerUrl = (url = '') => {
  return url.includes('vimeo.com/') && !url.includes('player.vimeo.com/video/')
    ? url.replace('vimeo.com/', 'player.vimeo.com/video/')
    : url
}

export const VideoPlayer = ({ videoSrcURL, videoTitle }) => (
  <iframe
    src={playerUrl(videoSrcURL)}
    title={videoTitle}
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    frameBorder="0"
    webkitallowfullscreen="true"
    mozallowfullscreen="true"
    allowFullScreen
    loading="lazy"
  />
)
