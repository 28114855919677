import React from 'react'
import { Link } from 'gatsby'

export const Footer = () => {
  React.useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.style.opacity = 1
          } else {
            entry.target.style.opacity = 0
          }
        })
      },
      {
        root: null,
        rootMargin: '-80px',
        threshold: 0.02,
      }
    )

    document
      .querySelectorAll('section, footer')
      .forEach((item) => observer.observe(item))
  }, [])

  return (
    <footer className="py-5">
      <div className="row">
        <div className="col-md-10 offset-md-1 col-12 text-center">
          <p>
            Ein Angebot des Universitätsklinikums Hamburg-Eppendorf (UKE).{' '}
            <a
              className="bg-none"
              target="_blank"
              rel="noreferrer"
              href="https://www.uke.de/"
            >
              www.uke.de
            </a>
          </p>
        </div>
        <div className="col-md-2 offset-md-3 col-12 text-center">
          <a href="mailto:jukebox@uke.de">&gt; Kontakt</a>
        </div>
        <div className="col-md-2 col-12 text-center">
          <Link to="/impressum">&gt; Impressum</Link>
        </div>
        <div className="col-md-2 col-12 text-center">
          <Link to="/datenschutz">&gt; Datenschutz</Link>
        </div>
      </div>
    </footer>
  )
}
