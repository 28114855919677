import * as React from 'react'
import { Link } from 'gatsby'
import { Button } from '../../common/button'

export function StationsShortlist({ area, stations }) {
  stations.sort((a, b) => {
    if (a.header.title < b.header.title) {
      return -1
    }
    if (a.header.title > b.header.title) {
      return 1
    }
    return 0
  })

  const searchLink = '/' + area.toLowerCase() + '/stationen'
  return (
    <section id="stations" className="bg-img-yellow divider-center">
      <div
        id="accordion-example"
        className="col-md-8 offset-md-2 col-12 accordion"
      >
        <div className="row">
          <h5 className="mb-0">STÖBERE DURCH DIE BEREICHE:</h5>
        </div>
        <br />
        <div className="row">
          <ul className="link-list">
            {stations.map((station) => (
              <li id="searchStationTemplate" data-tags="" key={station.slug}>
                <h5 className="mb-0">
                  <Link className="bg-dark-blue" to={station.slug}>
                    {station.header.title}
                  </Link>
                </h5>
              </li>
            ))}
          </ul>
        </div>
        <div className="text-center">
          <Button color="blue" href={searchLink} className="mt-4">
            Stöbern
          </Button>
        </div>
      </div>
    </section>
  )
}
