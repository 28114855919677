import React from 'react'
import { StaticSections } from '../staticSections'

export function StaticReferenceModules({ referencesData }) {
  return referencesData.map((reference, index) => (
    <StaticSections
      key={index}
      referenceId={reference._id}
      sections={
        reference.content && reference.content.sections
          ? reference.content.sections
          : []
      }
      backgroundClassName="bg-grey"
    />
  ))
}
